<template>
    <div class="accordion" :class="{ active: props.isOpen }">
        <div v-show="slots.background" class="accordion-background">
            <slot name="background"></slot>
        </div>
        <div
            :id="label ? `accordion_heading_${props.label}` : undefined"
            class="accordion-header"
            role="tab"
            :aria-expanded="props.isOpen ? 'true' : 'false'"
            :aria-controls="label ? `accordion_${props.label}` : undefined"
            data-test="accordion-header"
            @click="onClickHeader"
        >
            <span class="accordion-title">
                <slot name="title"></slot>
            </span>
            <div class="accordion-icon">
                <slot name="icon">
                    <NuxtIcon name="ri:arrow-down-s-line" class="socials-icon" />
                </slot>
            </div>
        </div>
        <Transition
            name="collapse"
            @enter="onCollapseEnter"
            @after-enter="onCollapseAfterEnter"
            @leave="onCollapseLeave"
            @after-leave="onCollapseAfterLeave"
        >
            <div
                v-show="props.isOpen"
                :id="label ? `accordion_${props.label}` : undefined"
                :aria-labelledby="label ? `accordion_heading_${props.label}` : undefined"
                class="accordion-body"
                role="tabpanel"
                data-test="accordion-body"
            >
                <slot name="body"></slot>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
interface Props {
    isOpen: boolean;
    label?: string;
}

const props = defineProps<Props>();
const slots = useSlots();
const emit = defineEmits<{
    (e: 'toggle'): void;
}>();

function onClickHeader() {
    emit('toggle');
}

// hook for the start of opening animation
function onCollapseEnter(el: Element, done: Function) {
    // set height in px to animate it from 0
    (el as HTMLElement).style.height = `${el.scrollHeight}px`;
    setTimeout(done, 350);
}

// clear unnecessary styles after animation has ended
function onCollapseAfterEnter(el: Element) {
    (el as HTMLElement).style.height = '';
}

// hook for the start of closing animation
function onCollapseLeave(el: Element, done: Function) {
    // set height in px instead auto to be able to apply transition
    (el as HTMLElement).style.height = `${el.getBoundingClientRect()['height']}px`;
    //Force reflow
    getComputedStyle(el)['height'];
    // set height to 0 to perform animation
    (el as HTMLElement).style.height = '0px';
    setTimeout(done, 350);
}

// clear unnecessary styles after animation has ended
function onCollapseAfterLeave(el: Element) {
    (el as HTMLElement).style.height = '';
}
</script>
